<template>
  <div>soc--运维管理</div>
</template>

<script>
export default {
  name: "soc",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
